import React, { Component } from 'react'
import { Link } from "gatsby"
import throttle from 'lodash/throttle'

import Layout from "../components/layout"
import Logo from "../components/renderedImages/Logo"
import SEO from "../components/seo"
import data from '../data/content.json'
import Right from '../components/icons/right'
import ZoomIn from '../components/icons/ZoomIn'
import CloseIcon from '../components/icons/CloseIcon'
import Toggle from '../components/parts/Toggle'

export default class index extends Component {
    state = {
        promo: [],
        featuredWorks: []
    }
    componentDidMount()  {
        window.scrollTo(0,0);
        window.addEventListener('scroll', 
            throttle(()=> { this.CheckSlide() }, 150)
        )
        if (data) {
          this.setState({
            promo: data.promo,
            featuredWorks: data.featuredWorks
          });
        }
    }

    componentWillUnmount() {
      window.removeEventListener('scroll', this.CheckSlide());
    }

    CheckSlide(e) {
        const sliderImages = document.querySelectorAll('.slide-in');
        sliderImages.forEach( slide => {
          if (window.scrollY + window.innerHeight*.5 > slide.offsetTop)
            slide.classList.add('slide');
        })
    }
  
  render() {
    const { promo, featuredWorks } = this.state;

    if (promo === null & featuredWorks === null ) return <div className="home-loading">loading...</div> 
    return (
        <Layout>
            <SEO title="Home" description="Welcome to Tiger Press. An individually owned business dedicated to creating promotional products that will help you thrive." />
            <main className="hero">
                <div className="box-container">
                    <div className="box">
                        <h1>Promotional Products<br/>&amp; Custom Printing</h1>
                        <div className="promo-btn">
                            <Link to="/contact">Get a Quote</Link>
                        </div>
                    </div>
                </div>
            </main>

            <section className="home-section2">
                <h1 className="home-section2__header slide-in">What you can do with<br/><span><Logo /></span></h1>
                <div className="grid">
                    {promo.map( (gridItem, idx) => {
                        const {desc, img, title} = gridItem;
                        return (
                            <div className="grid-item slide-in" key={idx}>
                                <div className="grid-item-svg">
                                    <svg viewBox="0 0 20 20" alt="">
                                        <path d={img}/>
                                    </svg>
                                </div>
                                <h2>{title}</h2>
                                <p>{desc}</p>
                            </div>
                        )
                    })}
                </div>
                <div className="home-section2__more slide-in">
                    <Link to="/about" className="css-link">
                        Learn More {Right()}
                    </Link>
                </div>
            </section>

            <section className="home-section3">
                <h2 className="slide-in">Products</h2>
                <ul className="products-box-wrap">
                    {featuredWorks.map( (work, idx) => {
                        return (
                            <li className="products-box-container" key={idx}>
                                <Toggle>
                                    {({ on, toggle}) => (
                                        <>
                                        <div key={idx} className="products-box slide-in" onClick={toggle} role="button">
                                            <img src={`/images/${work.src}`} alt={`${work.name} product sample`} />
                                            <div className="img-detail">{ZoomIn()}</div>
                                        </div>
                                        { on ? 
                                            <div className="image-pop-container">
                                                <img className="image-pop" src={`/images/${work.src}`} alt={`${work.name} product sample`}/>
                                                <div className="image-pop-close" onClick={toggle}>{CloseIcon()}</div>
                                            </div> : null }
                                        { on ? <div className="js-overlay" onClick={toggle}></div> : null }
                                        </>
                                    )}
                                </Toggle>
                            </li>
                        )
                    })}
                </ul>
                <div className="home-section3__more slide-in">
                    <Link to="/products" className="css-link">
                        See More {Right()}
                    </Link>
                </div>
            </section>

            <section className="home-section4">
                <div className="start">
                    <div className="slide-in">Getting started is easy!</div>
                    <Link to="/contact" className="css-link slide-in">Contact us{Right()}</Link>
                </div>
            </section>
        </Layout>
    )
  }
}